.Login {
    text-align: center;
  }

.Login-container {
    text-align: center;
    /* background-color: #17171E;
    color: #FFFFFF;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    min-height: 100vh;
    display: flex; */
    /* place-items: center; Center content both horizontally and vertically */
}


.input
{
    width: 100%; 
    background-color: #1e1e1e;
    font-size: 20px;
    border-radius: 10px;
    padding: 10px;
    color: white;
    border-style: solid;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
}